<template>
  <ion-footer>
    <ion-toolbar>
      <ion-img src="assets/pix/footer.png"></ion-img>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonFooter,
  IonToolbar,
  IonImg
} from '@ionic/vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'mainFooter',
  components: {
    IonFooter,
    IonToolbar,
    IonImg
  }
});
</script>
