<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="3" headline="Dachart"></partials-steps>
      <ion-grid>
        <ion-col>
          Bitte wählen Sie eine Dachart:
        </ion-col>


        <ion-row v-for="value in values" :key="value.id">
          <ion-col>
            <label :for="value.replace(/\s+/g, '')" class="btn-clickable pic-clickable">
              <ion-col>
                <img :src="getImagePath(value)" :alt="value">
              </ion-col>
              <ion-col>
                {{ value }}
              </ion-col>
              <input :id="value.replace(/\s+/g, '')" v-model="dachart" @click="changePage()" type="radio" :value="value">
            </label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" size="4" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {store} from "@/store/formtemplate";
import partialsSteps from "@/views/partials/steps";
import {dachlattung} from "@/store/dachlattung";

export default defineComponent({
  name: 'Dachart',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    dachart: {
      get () {
        return store.getters.getByProperty("dachart")
      },
      set (value) {
        store.commit("set", {property: 'dachart', value:value});
      }
    },
    values: {
      get() {
        const filtered = dachlattung.getters.getFilterd;

        return [...new Set(filtered.map(item => item.dachart))];
      }
    }
  },
  methods: {
    getImagePath: function(name){
      const mapping = {
        "Sattel- und Walmdach": "assets/pix/satteldach.svg",
        "Pultdach": "assets/pix/pultdach.svg",
      };

      return mapping[name];
    },

    changePage: function() {
      this.$router.push("/Dachlattung/Gebauedehoehe");
    },

    goBack: function () {
      store.commit("set", {property: 'dachart', value: ""});
      store.commit("set", {property: 'windzone', value: ""});

      this.$router.back();
    }
  }
});
</script>
