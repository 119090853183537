<template>
  <ion-page>
    <ion-content v-if="product">
      <ion-grid>
        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button routerLink="/home" v-if="isDirectView">Neue Suche Starten</ion-button>
            <ion-button @click="goBack()" v-else>Zurück zu den Suchergebnissen</ion-button>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <img :src="getProductImage(product.Bild)">
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <h1>{{ product.Titel }}</h1>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <span>Artikelnummer: {{ product.Artikelnummer }}</span><br>
            <span>Drahtdurchmesser: {{ product.Drahtdurchmesser }}</span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-primary small-padding" size="7" mode="ios">
            <a :href="product.Website1" target="_blank">
              <ion-button>Zur Produktseite</ion-button>
            </a>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-secondary" size="6" mode="ios">
            <a @click="shareProduct">
              <ion-button>Produkt teilen</ion-button>
            </a>
          </ion-col>
        </ion-row>

        <!--Produktdetails Table -->
        <ion-row>
          <ion-col>
            <h2>Produktdetails</h2>
          </ion-col>
        </ion-row>

        <div class="table">
          <ion-row>
            <ion-col>
              Länge
            </ion-col>
            <ion-col>
              {{ product.Laenge }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Ausführung
            </ion-col>
            <ion-col>
              {{ product.Ausfuehrung }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Norm
            </ion-col>
            <ion-col>
              {{normen}}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Anzahl pro qm
            </ion-col>
            <ion-col>
              {{ product.Befestigungsmittel }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Passt zu Gerät
            </ion-col>
            <ion-col>
              {{ fits }}
            </ion-col>
          </ion-row>
        </div>


        <ion-row class="ion-margin-top">
          <ion-col>
            Randabstände
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div v-if="getRandabstandImage !== false">
              <img :src="getRandabstandImage" alt="Randabstand" />
            </div>
            <div v-else>
              <b>Bitte entnehmen Sie die Randabstände den Technischen Informationen bzw. Vorgaben des Herstellers. </b>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button routerLink="/home" v-if="isDirectView">Neue Suche Starten</ion-button>
            <ion-button @click="goBack()" v-else>Zurück zu den Suchergebnissen</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  alertController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import {productsstore} from "@/store/products";
import axios from "axios";
import {dachlattung} from "@/store/dachlattung";

export default defineComponent({
  name: 'ProduktDetail',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol
  },
  created() {
    if(store.getters.getByProperty("verbindungsart") === ""){
      this.isDirect = true;
    }

    /*
    if(this.$route.params.code){
      axios.get('https://backend.itw-i-select.com/get/setting/'+this.$route.params.code)
          .then(response => {
            store.commit("setAll", {data: JSON.parse(response.data.data)});

            if(response.data.version !== "1.0")
              this.presentAlert();
        });
    }*/
  },
  data() {
    return{
      isDirect: false,
    }
  },
  computed: {
    product: {
      get() {
        return productsstore.getters.getById(this.$route.params.id);
      }
    },
    normen: {
      get() {
        const product = productsstore.getters.getById(this.$route.params.id);
        const temp = [];
        const names = ["Norm1", "Norm2", "Norm3"];

        names.forEach(function(name){
          if(product[name] != null)
            temp.push(product[name]);
        });

        return temp.length === 1 ? temp[0]: temp.join(", ");
      }
    },
    fits: {
      get() {
        const product = productsstore.getters.getById(this.$route.params.id);
        const temp = [];
        const names = ["Product1", "Product2", "Product3"];

        names.forEach(function(name){
          if(product[name] != null)
            temp.push(product[name]);
        });

        return temp.length === 1 ? temp[0]: temp.join(", ");
      }
    },
    isDirectView: function() {
      return this.isDirect;
    },
    getRandabstandImage: function(){
      const product = productsstore.getters.getById(this.$route.params.id);

      const mapping = {
        "Streifennägel": "assets/pix/content/randabstand/randabstand_nagel_",
        "Draht-Coilnägel": "assets/pix/content/randabstand/randabstand_nagel_",
        "Plast-Coilnägel": "assets/pix/content/randabstand/randabstand_nagel_",
        "Klammern": "assets/pix/content/randabstand/randabstand_klammer_"
      };

      const mappingPlattenart = {
        "OSB/3 oder OSB/4": "hws_1",
        "Spanplatten (≥P5)": "hws_1",
        "Sperrholz": "hws_1",
        "Zementgebundene Spanplatten": "hws_2",
        "Massivholzplatten": "hws_2",
        "Holzfaserdämmplatte": false,
        "Gipsplatte": "hws_3",
        "Gipsfaserplatte": "hws_4",
        "Brandschutzplatte": false,
      }

      //we need to switch here, cause the way to get that image differs
      if(store.getters.getByProperty("verbindungsart") === "holzwerkstoffplatte"){
        if(mappingPlattenart[store.getters.getByProperty("plattenunterart")] === false){
          return false
        }else{
          return mapping[product.Typ]+mappingPlattenart[store.getters.getByProperty("plattenunterart")]+".png"
        }
      }else{
        return "assets/pix/content/randabstand/"+dachlattung.getters.getFilterd[0]['randabstand'];
      }
    },
  },
  methods: {
    getProductImage: function(name) {
      let temp = "assets/pix/content/";

      if(name == null){
        temp += "testbild.jpg";
      }else{
        temp += name;
      }

      return temp
    },

    async shareProduct() {
      //let code = "";
      const product = productsstore.getters.getById(this.$route.params.id)

      //url wurde direkt im Browser eingefügt, ohne Konfig ohne Code.
      if(store.getters.getByProperty("verbindungsart") === ""){
        const alert = await alertController
            .create({
              header: 'Achtung',
              message: 'Dieser Link wurde direkt Aufgerufen, es gibt keine Konfiguration. Bitte starten Sie zuerst eine neue Suche!',
              buttons: ['Verstanden'],
            });
        await alert.present()
      }else{
        if (navigator.share) {
          navigator.share({
            title: product.titel,
            url: window.location.origin+ "/produkt/"+product.Artikelnummer
          });
        }
        else {
          const text = 'mailto:?'+
              'subject= iSelect Produkt&'+
              'body=Folgendes Produkt habe ich in der iSelect App gefunden: '+
              window.location.origin+ "/produkt/"+product.Artikelnummer;

          window.open(text)
        }
      }
    },
    goBack: function() {
      this.$router.back();
    },
    async presentAlert() {
      const alert = await alertController
          .create({
            header: 'Achtung',
            message: 'Dieser Link wurde für eine ältere App-Version erstellt, es kann zu einer falschen Darstellung ' +
                'des Produktes kommen.',
            buttons: ['Verstanden'],
          });
      await alert.present();
    },
  },
});
</script>
