<template>
  <div class="stepWrapper">
    <ion-row>
      <ion-col>Schritt {{active}}/{{steps}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col><h1>{{headline}}</h1></ion-col>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonCol
} from '@ionic/vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Steps',
  components: {
    IonRow,
    IonCol
  },
  props: {
    steps: String,
    active: String,
    headline: String
  }
});
</script>
