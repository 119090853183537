<template>
  <ion-header>
    <ion-toolbar>
      <ion-row>
        <ion-col size="7"><router-link to="/home"><ion-img src="assets/pix/logo.svg"></ion-img></router-link></ion-col>
        <ion-col size="1" class="logo"></ion-col>
        <ion-col size="4">
          <ion-menu-button autoHide="false" menu="mainMenu" type="reveal" mode="md" @click="toggleMenu">
            <ion-icon name="close" v-if="menuOpen"></ion-icon>
            <ion-icon name="menu-outline" v-else></ion-icon>
          </ion-menu-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>

  <ion-menu v-on:ionDidClose="menuClosed" side="end" type="push" content-id="main" menu-id="mainMenu" mode="md">
    <ion-content class="menu" mode="md">
      <ion-list class="menuList" mode="md">
        <router-link to="/home"><ion-item>Startseite</ion-item></router-link>
        <a href="https://www.itw-befestigungssysteme.de" target="_blank"><ion-item>ITW Webseite</ion-item></a>
        <a href="https://www.itw-bemessungssoftware.de" target="_blank"><ion-item>i-WOOD</ion-item></a>
        <router-link to="/rechtliches/impressum"><ion-item>Impressum</ion-item></router-link>
        <router-link to="/rechtliches/datenschutz"><ion-item>Datenschutzerklärung</ion-item></router-link>
        <router-link to="/rechtliches/nutzungsbedingungen"> <ion-item>Nutzungsbedingungen</ion-item></router-link>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonImg,
  IonMenuButton,
  IonMenu,
  IonList,
  IonItem,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'mainHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonImg,
    IonMenuButton,
    IonMenu,
    IonList,
    IonItem,
    IonContent,
    IonRow,
    IonCol,
    IonIcon
  },
  data() {
    return{
      menuOpen: false
    }
  },
  methods: {
    toggleMenu: function() {
      this.menuOpen = !this.menuOpen;
    },
    menuClosed: function(){
      this.menuOpen = false;
    }
  },
});
</script>
