import { createStore } from "vuex";
import axios from "axios";
import {store} from "@/store/formtemplate";

export const dachlattung = createStore({
    state: {
        dachlattung: {}
    },

    getters: {
        getAll (state: any) {
            return state.dachlattung;
        },

        getFilterd (state: any) {
            const dachlattungTemp = state.dachlattung;
            const formTemplateTemp = store.getters.getAll;

            const result = dachlattungTemp.filter(function (e: any) {
                const temp = [];
                let val;

                for( val in formTemplateTemp){
                    if(formTemplateTemp[val] != ""){
                        temp.push(formTemplateTemp[val] == e[val] ? true: false);
                    }
                }

                return temp.every(e => e === true);
            });

            return result;
        }
    },

    // MUTATIONS ( set the state )
    mutations: {
        set(state: any, data: any) {
            state.dachlattung = data;
        }
    },

    actions: {
        fetchDachlattung (store) {
            return axios.get('https://backend.itw-i-select.com/get/filter/dachlattung')
                .then(response => {
                    store.commit('set', response.data)
                })
                .catch(error => {
                    // set error state
            })
        }
    }
});
