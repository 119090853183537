<template>
  <ion-page>

    <ion-content class="einbausituation">
      <partials-steps steps="5" active="5" headline="Einbausituation"></partials-steps>
      <ion-grid>
        <ion-row>
          <ion-col>
            Bitte wählen Sie die Einbausituation:
          </ion-col>
        </ion-row>

        <ion-row class="ion-justify-content-center">
          <ion-col v-for="value in values" :key="value.id" class="ion-margin-bottom" size="6">
            <label :for="value.replace(/\s+/g, '')" class="btn-clickable pic-clickable-small">
              <ion-col>
                <img :src="getImagePath(value)" :alt="value">
              </ion-col>
              <ion-col>
                {{ value }}
              </ion-col>
              <input :id="value.replace(/\s+/g, '')" v-model="einbausituation" @click="changePage()" type="radio" :value="value">
            </label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import {holzhws} from "@/store/holzhws";
import partialsSteps from "@/views/partials/steps";


export default defineComponent({
  name: 'Einbausituation',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    einbausituation: {
      get() {
        return store.getters.getByProperty("einbausituation")
      },
      set(value) {
        store.commit("set", {property: 'einbausituation', value: value});
      }
    },
    values: {
      get() {
        const filtered = holzhws.getters.getFilterd;

        return [...new Set(filtered.map(item => item.einbausituation))];
      }
    }
  },
  methods: {
    getImagePath: function(name){
      const mapping = {
        "Innen": "assets/pix/content/Logo_Einbausituation_Innen.png",
        "Außen überdacht": "assets/pix/content/Logo_Einbausituation_Aussenueberdacht.png",
        "Außen": "assets/pix/content/Logo_Einbausituation_Aussen.png",
      };

      return mapping[name];
    },
    changePage: function () {
      this.$router.push("/Ergebniss");
    },

    goBack: function () {
      store.commit("set", {property: 'einbausituation', value: ""});
      store.commit("set", {property: 'plattendicke', value: ""});

      this.$router.back();
    }
  }
});

</script>
