<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="5" headline="Sparrenabstand"></partials-steps>

      <ion-grid>
        <ion-row v-for="value in values" :key="value.id">
          <ion-col>
            <label :for="value.replace(/\s+/g, '')" class="btn-clickable">
              <ion-col>
                <span v-html="getName(value)"></span>
              </ion-col>
              <input :id="value.replace(/\s+/g, '')" v-model="sparrenabstand" @click="changePage()" type="radio" :value="value">
            </label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" size="4" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import partialsSteps from "@/views/partials/steps";
import {dachlattung} from "@/store/dachlattung";

export default defineComponent({
  name: 'Sparrenabstand',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    sparrenabstand: {
      get() {
        return store.getters.getByProperty("sparrenabstand")
      },
      set(value) {
        store.commit("set", {property: 'sparrenabstand', value: value});
      }
    },
    values: {
      get() {
        const filtered = dachlattung.getters.getFilterd;

        return [...new Set(filtered.map(item => item.sparrenabstand))];
      }
    }
  },
  methods: {
    getName: function(value){
      const mapping = {
        "≤ 0,8 m": "&#8804; 0,8m Dachlatte 30/50 oder 40/60",
        "≤ 1,0 m": "&#8804; 1,0m Dachlatte 40/60",
      };

      return mapping[value];
    },
    changePage: function () {
      this.$router.push("/ergebniss");
    },

    goBack: function () {
      store.commit("set", {property: 'sparrenabstand', value: ""});
      store.commit("set", {property: 'gebaeudehoehe', value: ""});

      this.$router.back();
    }
  }
});
</script>
