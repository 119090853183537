import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

/*Allgemeines*/
import Home from '../views/Home.vue'
import Impressum from '../views/Rechtliches/Impressum.vue'
import Datenschutz from '../views/Rechtliches/Datenschutz.vue'
import Nutzungsbedingungen from '../views/Rechtliches/Nutzungsbedingungen.vue'
import keinErgebnis from '../views/Zwischenseite.vue'

/*Produkt Allgemein*/
import Verbindungsart from '@/views/Verbindungsart.vue'
import Ergebniss from '@/views/Ergebniss.vue'
import ProduktDetail from '@/views/ProduktDetail.vue'

/*Holz-HW*/
import Plattenart from '@/views/Holzwerkstoffplatte/2_Plattenart.vue'
import Gipswerkstoffplatten from '@/views/Holzwerkstoffplatte/3_Gipswerkstoffplatten.vue'
import Holzwerkstoffplatten from '@/views/Holzwerkstoffplatte/3_Holzwerkstoffplatten.vue'
import Plattendicke from '@/views/Holzwerkstoffplatte/4_Plattendicke.vue'
import Einbausituation from '@/views/Holzwerkstoffplatte/5_Einbausituation.vue'

/*Dachlattung*/
import Windzone from '@/views/Dachlattung/2_Windzone.vue'
import Dachart from '@/views/Dachlattung/3_Dachart.vue'
import Gebauedehoehe from '@/views/Dachlattung/4_Gebaeudehoehe.vue'
import Sparrenabstand from '@/views/Dachlattung/5_Sparrenabstand.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/rechtliches/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/rechtliches/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/rechtliches/nutzungsbedingungen',
    name: 'Nutzungsbedingungen',
    component: Nutzungsbedingungen
  },
  {
    path: '/verbindungsart',
    name: 'Verbindungsart',
    component: Verbindungsart
  },
  {
    path: '/Holzwerkstoffplatte/Plattenart',
    name: 'Holz_Plattenart',
    component: Plattenart
  },
  {
    path: '/Holzwerkstoffplatte/Holzwerkstoffplatten',
    name: 'Holz_Holzwerkstoffplatten',
    component: Holzwerkstoffplatten
  },
  {
    path: '/Holzwerkstoffplatte/Gipswerkstoffplatten',
    name: 'Holz_Gipswerkstoffplatten',
    component: Gipswerkstoffplatten
  },
  {
    path: '/Holzwerkstoffplatte/Plattendicke',
    name: 'Holz_Plattendicke',
    component: Plattendicke
  },
  {
    path: '/Holzwerkstoffplatte/Einbausituation',
    name: 'Holz_Einbausituation',
    component: Einbausituation
  },
  {
    path: '/Dachlattung/Windzone',
    name: 'Dach_Windzone',
    component: Windzone
  },
  {
    path: '/Dachlattung/Dachart',
    name: 'Dach_Dachart',
    component: Dachart
  },
  {
    path: '/Dachlattung/Gebauedehoehe',
    name: 'Dach_Gebauedehoehe',
    component: Gebauedehoehe
  },
  {
    path: '/Dachlattung/Sparrenabstand',
    name: 'Dach_Sparrenabstand',
    component: Sparrenabstand
  },
  {
    path: '/ergebniss',
    name: 'ergebniss',
    component: Ergebniss
  },
  {
    path: '/keinErgebnis',
    name: 'keinErgebnis',
    component: keinErgebnis
  },
  {
    path: '/produkt/:id/:code?',
    name: 'produkt',
    component: ProduktDetail
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
