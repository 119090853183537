<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="4" headline="Plattendicke"></partials-steps>
      <ion-grid>
        <ion-row>
          <ion-col>
            Bitte wählen Sie die Plattendicke:
          </ion-col>
        </ion-row>
        <div v-for="plattendickeValues in values" :key="plattendickeValues.id">
          <ion-row>
            <ion-col>
              <label :for="'plattendicke_' + plattendickeValues" class="btn-clickable">
                <ion-col>
                  {{ plattendickeValues }} mm
                </ion-col>
                <input v-model="plattendicke" @click="changePage()" type="radio" :value="plattendickeValues"
                       :id="'plattendicke_' + plattendickeValues">
              </label>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import {holzhws} from "@/store/holzhws";
import partialsSteps from "@/views/partials/steps";


export default defineComponent({
  name: 'Plattendicke',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    plattendicke: {
      get() {
        return store.getters.getByProperty("plattendicke")
      },
      set(value) {
        store.commit("set", {property: 'plattendicke', value: value});
      }
    },
    values: {
      get() {
        const filtered = holzhws.getters.getFilterd;

        return [...new Set(filtered.map(item => item.plattendicke))];
      }
    }
  },
  methods: {
    changePage: function () {
      this.$router.push("/Holzwerkstoffplatte/Einbausituation");
    },

    goBack: function () {
      store.commit("set", {property: 'plattendicke', value: ""});
      store.commit("set", {property: 'plattenunterart', value: ""});

      this.$router.back();
    }
  }
});
</script>
