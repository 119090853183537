<template>
  <ion-page>
    <ion-content ref="content">
      <partials-steps steps="5" active="2" headline="Windzone"></partials-steps>
      <ion-grid>
        <ion-row>
          <ion-col>
            Bitte wählen Sie die Windzone:
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center img-top">
            <img src="assets/pix/windzonen_v2.svg" height="286">
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <label for="zone1" class="btn-clickable">
              <ion-col>
                Zone 1
              </ion-col>
              <input id="zone1" v-model="windzone" @click="changePage()" type="radio" value="1">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <label for="zone2" class="btn-clickable" @click="scrollToBottom">
              <ion-col>
                Zone 2
              </ion-col>
              <input id="zone2" v-model="windzone" type="radio" value="2">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <label for="zone3" class="btn-clickable" @click="scrollToBottom">
              <ion-col>
                Zone 3
              </ion-col>
              <input id="zone3" v-model="windzone" type="radio" value="3">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <label for="zone4" class="btn-clickable" @click="scrollToBottom">
                <ion-col>
                  Zone 4
                </ion-col>
              <input id="zone4" v-model="windzone" type="radio" value="4">
            </label>
          </ion-col>
        </ion-row>

        <div v-if="isLageDisabled">
          <ion-row v-if="isLageDisabled">
            <ion-col>Bitte wählen Sie außerdem:</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <label for="binnenland" class="btn-clickable">
                <ion-col>
                  Binnenland
                </ion-col>
                <input id="binnenland" v-model="lage" @click="changePage()" type="radio" value="Binnenland">
              </label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <label for="kueste" class="btn-clickable">
                <ion-col>
                  Küste
                </ion-col>
                <input id="kueste" v-model="lage" @click="changePage()" type="radio" value="Küste">
              </label>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col>
            <div class="hinweis">
              <p><b>Küste</b>: Küstennahe Gebiete in einem Streifen mit 5 km Breite landeinwärts sowie auf den Inseln der Nord-
                und Ostsee. Naturgemäß hohe Belastung durch Chloride.</p>
              <p>ITW Empfehlung: Verwendung von Rillennägeln der Nutzungs- <br>klasse 3.</p>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" size="4" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {store} from "@/store/formtemplate";
import {dachlattung} from "@/store/dachlattung";
import {productsstore} from "@/store/products";
import partialsSteps from "@/views/partials/steps";

export default defineComponent({
  name: 'Windzone',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  setup() {
    const content = ref();
    const scrollToBottom = () => {
      content.value.$el.scrollToPoint(0, 400, 300);
    }
    return { content, scrollToBottom }
  },
  computed: {
    isLageDisabled() {
      return store.getters.getByProperty("windzone") === "2" || store.getters.getByProperty("windzone") === "3" || store.getters.getByProperty("windzone") === "4";
    },
    windzone: {
      get() {
        return store.getters.getByProperty("windzone")
      },
      set(value) {
        store.commit("set", {property: 'windzone', value: value});

        if(store.getters.getByProperty("windzone") === "1")
          store.commit("set", {property: 'lage', value: "n/a"});
      }
    },
    lage: {
      get() {
        return store.getters.getByProperty("lage")
      },
      set(value) {
        store.commit("set", {property: 'lage', value: value});
      }
    },
  },
  methods: {
    changePage: function () {
      this.$router.push("/Dachlattung/Dachart");
    },
    goBack: function () {
      store.commit("set", {property: 'windzone', value: ""});
      store.commit("set", {property: 'lage', value: ""});

      this.$router.back();
    }
  }
});
</script>
