<template>
  <ion-page>
    <ion-content class="home">
      <ion-grid>
        <ion-row>
          <ion-col class="ion-text-center">
            <p>Nutzen Sie unsere App, um auf einfache Art und Weise das richtige Befestigungsmittel für Ihre Anwendung
              zu finden.</p>
            <img src="assets/pix/content/StartseiteApp.jpg" alt="Holzwerkstoffplatten">
            <div class="btn-primary btn-big" mode="ios">
              <ion-button class="button-round" expand="full" router-link="/verbindungsart">
                Nutzungsbedingungen akzeptieren und Produkt finden
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonCol, IonButton, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter} from "vue-router";

export default defineComponent({
  name: 'Home',
  setup() {
    return {
      router: useRouter()
    }
  },
  components: {
    IonContent,
    IonGrid,
    IonCol,
    IonPage,
    IonButton,
    IonRow
  },
});


</script>
