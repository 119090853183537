
import { IonContent, IonPage, IonGrid, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter} from "vue-router";

export default defineComponent({
  name: 'Datenschutz',
  setup() {
    return {
      router: useRouter()
    }
  },
  components: {
    IonContent,
    IonGrid,
    IonCol,
    IonPage
  },
  mounted() {
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', 'https://consent.cookiebot.com/4e397f2b-deb1-4352-a333-13693774b0d8/cd.js');
    scriptEl.setAttribute('id', 'CookieDeclaration');
    scriptEl.setAttribute('type', 'text/javascript');
    (this.$refs.scriptHolder as HTMLDivElement).appendChild(scriptEl);
  },
});

