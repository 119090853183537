
import {IonApp, IonRouterOutlet, onIonViewDidEnter} from '@ionic/vue';
import { defineComponent } from 'vue';
import partialsHeader from "@/views/partials/header.vue";
import partialsFooter from "@/views/partials/footer.vue";
import {dachlattung} from "@/store/dachlattung";
import {productsstore} from "@/store/products";
import {holzhws} from "@/store/holzhws";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    partialsHeader,
    partialsFooter
  },
  beforeCreate() {
    productsstore.dispatch("fetchProducts");
    dachlattung.dispatch("fetchDachlattung");
    holzhws.dispatch("fetchHolzhws");
  },
});
