<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="4" headline="Gebäudehöhe"></partials-steps>

      <ion-grid>
        <ion-col>
          Wählen Sie die Gebäudehöhe:
        </ion-col>
        <ion-col class="ion-text-center img-top">
          <img src="assets/pix/hoehe.svg" alt="Gebäudegrafik" width="300">
        </ion-col>

        <ion-row v-for="value in values" :key="value.id">
          <ion-col>
            <label :for="value.replace(/\s+/g, '')" class="btn-clickable">
              <ion-col>
                {{ value }}
              </ion-col>
              <input :id="value.replace(/\s+/g, '')" v-model="gebaeudehoehe" @click="changePage()" type="radio" :value="value">
            </label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div class="hinweis">
              Erläuterung:<br>
              Die Gebäudehöhe entspricht der Firsthöhe.
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="btn-back" size="4" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import partialsSteps from "@/views/partials/steps";
import {dachlattung} from "@/store/dachlattung";

export default defineComponent({
  name: 'Gebaeudehoehe',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    gebaeudehoehe: {
      get() {
        return store.getters.getByProperty("gebaeudehoehe")
      },
      set(value) {
        store.commit("set", {property: 'gebaeudehoehe', value: value});
      }
    },
    values: {
      get() {
        const filtered = dachlattung.getters.getFilterd;

        return [...new Set(filtered.map(item => item.gebaeudehoehe))];
      }
    }
  },
  methods: {
    changePage: function () {
      this.$router.push("/Dachlattung/Sparrenabstand");
    },

    goBack: function () {
      store.commit("set", {property: 'gebaeudehoehe', value: ""});
      store.commit("set", {property: 'dachart', value: ""});

      this.$router.back();
    }
  }
});
</script>
