<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="2" headline="Plattenart"></partials-steps>
      <ion-grid>
        <ion-col>
          Bitte wählen Sie die Plattenart:
        </ion-col>


        <ion-row v-for="value in values" :key="value.id">
          <ion-col>
            <label :for="value.replace(/\s+/g, '')" class="btn-clickable pic-clickable">
              <ion-col>
                <img :src="getImagePath(value)" :alt="value">
              </ion-col>
              <ion-col>
                {{ value }}
              </ion-col>
              <input :id="value.replace(/\s+/g, '')" v-model="plattenart" @click="changePage()" type="radio" :value="value">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="hinweis">
              <b>Hinweis: </b>Die Auswahl der Befestigungsmittel kann von der Empfehlung des Plattenherstellers
              abweichen.
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {store} from "@/store/formtemplate";
import {holzhws} from "@/store/holzhws";
import partialsSteps from "@/views/partials/steps";


export default defineComponent({
  name: 'Plattenart',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    plattenart: {
      get () {
        return store.getters.getByProperty("plattenart")
      },
      set (value) {
        store.commit("set", {property: 'plattenart', value:value});
      }
    },
    values: {
      get() {
        const filtered = holzhws.getters.getFilterd;

        return [...new Set(filtered.map(item => item.plattenart))];
      }
    }
  },
  methods: {
    getImagePath: function(name){
      const mapping = {
        "Gipswerkstoffplatte": "assets/pix/content/Gipskarton.jpg",
        "Holzwerkstoffplatte": "assets/pix/content/OSB.jpg",
      };

      return mapping[name];
    },
    changePage: function() {
      const targets = {
        Gipswerkstoffplatte: '/Holzwerkstoffplatte/Gipswerkstoffplatten',
        Holzwerkstoffplatte: '/Holzwerkstoffplatte/Holzwerkstoffplatten',
      }

      setTimeout(() => {
        this.$router.push(targets[store.getters.getByProperty("plattenart")]);
      }, 50);
    },

    goBack: function () {
      store.commit("set", {property: 'plattenart', value: ""});

      this.$router.back();
    }
  }
});
</script>
