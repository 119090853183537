
import { IonContent, IonPage, IonGrid, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter} from "vue-router";

export default defineComponent({
  name: 'Nutzungsbedingungen',
  setup() {
    return {
      router: useRouter()
    }
  },
  components: {
    IonContent,
    IonGrid,
    IonCol,
    IonPage
  },
});

