import { createStore } from "vuex";

export const store = createStore({
    state() {
        return {
            formTemplate: {
                verbindungsart: "",
                plattenart: "",
                windzone: "",
                lage: "",
                moeglicheLattung: "",
                naegel: "",
                dachart: "",
                sparrenabstand: "",
                gebaeudehoehe: "",
                plattenunterart: "",
                einbausituation: ""
            }
        };
    },

    getters: {
        getAll (state) {
            return state.formTemplate;
        },
        getByProperty: (state: any) => (property: string) => {
            return state.formTemplate[property]
        },
    },

    // MUTATIONS ( set the state )
    mutations: {
        set(state: any, data: any) {
            state.formTemplate[data.property] = data.value;
        },
        setAll(state: any, data: any) {
            state.formTemplate = data.data;
        },
        completeReset(state: any) {
            state.formTemplate = {
                verbindungsart: "",
                plattenart: "",
                windzone: "",
                lage: "",
                moeglicheLattung: "",
                naegel: "",
                dachart: "",
                sparrenabstand: "",
                gebaeudehoehe: "",
                plattenunterart: "",
                einbausituation: ""
            }
        }
    },
    actions: {
        resetStore (store) {
            store.commit('completeReset');
        }
    }
});
