import { createStore } from "vuex";
import axios from "axios";
import {store} from "@/store/formtemplate";
import {dachlattung} from "@/store/dachlattung";
import {holzhws} from "@/store/holzhws";
import router from '@/router';

export const productsstore = createStore({
    state() {
        return {
            products: {}
        };
    },

    getters: {
        getAll (state) {
            return state.products;
        },

        getById: (state: any) => (id: number) => {
            return typeof state.products[id] == 'object' ? state.products[id] : "";
        },

        getFilteredDachlattung (state: any, getters: any) {
            let temp = false;
            let showProducts = false;
            const filteredProducts: any[] = [];
            const filteredProductsTypes: any = {"products": {"Klammern": [], "Streifennägel": [], "Draht-Coilnägel": [], "Plast-Coilnägel": []}, "showNagelInfo": false, "showProducts": false};
            const productCols = ["produkt1","produkt2","produkt3","produkt4","produkt5","produkt6",]

            dachlattung.getters.getFilterd.forEach(function(element: any){
                if(element['naegel'] == "2"){
                    temp = true;
                }

                productCols.forEach(function(value: string, index: number){
                    if(element[value] != null){
                        if(productsstore.getters.getById(element[value]) !== ""){
                            filteredProducts.push(productsstore.getters.getById(element[value]));
                            showProducts = true;
                        }
                    }
                });
            });

            filteredProducts.forEach(function (element: any){
                filteredProductsTypes.products[element['Typ']].push(element);
            });

            filteredProductsTypes['showNagelInfo'] = temp;
            filteredProductsTypes['showProducts'] = showProducts;

            if(!showProducts)
                router.push("/keinErgebnis");

            return filteredProductsTypes;
        },

        getFilteredHolzhws (state: any, getters: any) {
            let temp = false;
            let showProducts = false;
            const filteredProducts: any[] = [];
            const filteredProductsTypes: any = {"products": {"Klammern": [], "Streifennägel": [], "Draht-Coilnägel": [], "Plast-Coilnägel": []}, "showNagelInfo": false, "showProducts": false};
            const productCols = ["produkt1","produkt2","produkt3","produkt4","produkt5","produkt6",]

            holzhws.getters.getFilterd.forEach(function(element: any){
                if(element['naegel'] == "2"){
                    temp = true;
                }

                productCols.forEach(function(value: string, index: number){
                    if(element[value] != null){
                        if(productsstore.getters.getById(element[value]) !== ""){
                            filteredProducts.push(productsstore.getters.getById(element[value]));
                            showProducts = true;
                        }
                    }
                });
            });

            filteredProducts.forEach(function (element: any){
                filteredProductsTypes.products[element['Typ']].push(element);
            });

            filteredProductsTypes['showNagelInfo'] = temp;
            filteredProductsTypes['showProducts'] = showProducts;

            if(!showProducts)
                router.push("/keinErgebnis");

            return filteredProductsTypes;
        }
    },

    // MUTATIONS ( set the state )
    mutations: {
        set(state: any, data: any) {
            state.products = data;
        },
    },

    actions: {
        fetchProducts (store) {
            return axios.get('https://backend.itw-i-select.com/get/products')
                .then(response => {
                    store.commit('set', response.data)
                })
                .catch(error => {
                    // ToDo: set error State
                })
        }
    }
});
