import { createStore } from "vuex";
import axios from "axios";
import {store} from "@/store/formtemplate";

export const holzhws = createStore({
    state: {
        holzhws: {}
    },

    getters: {
        getAll (state: any) {
            return state.holzhws;
        },

        getFilterd (state: any) {
            const holzhwsTemp = state.holzhws;
            const formTemplateTemp = store.getters.getAll;

            const result = holzhwsTemp.filter(function (e: any) {
                const temp = [];
                let val;

                for( val in formTemplateTemp){
                    if(formTemplateTemp[val] != ""){
                        temp.push(formTemplateTemp[val] == e[val] ? true: false);
                    }
                }

                return temp.every(e => e === true);
            });

            return result;
        }
    },

    // MUTATIONS ( set the state )
    mutations: {
        set(state: any, data: any) {
            state.holzhws = data;
        }
    },

    actions: {
        fetchHolzhws (store) {
            return axios.get('https://backend.itw-i-select.com/get/filter/holzhws')
                //{headers: { "Authorization": "Basic " + btoa("rocket-media:47115")}})
                .then(response => {
                    store.commit('set', response.data)
                })
                .catch(error => {
                    // set error state
                })
        }
    }
});
