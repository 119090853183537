<template>
  <ion-page>
    <ion-content>
      <partials-steps steps="5" active="1" headline="Verbindungsart"></partials-steps>
      <ion-grid>
        <ion-col>
          Bitte wählen Sie eine Verbindungsart:
        </ion-col>
        <ion-row>
          <ion-col>
            <label for="holzwerkstoffplatte" class="pic-clickable btn-clickable">
              <ion-col>
                <img src="assets/pix/content/Holzwerkstoff.jpg" alt="Holz-Werkstoff-Verbindungen">
              </ion-col>
              <ion-col>
                <span>Holz-Werkstoff-Verbindungen</span>
              </ion-col>
              <input id="holzwerkstoffplatte" v-model="verbindungsart" @click="changePage()" type="radio"
                     value="holzwerkstoffplatte">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <label for="dachlattung" class="btn-clickable pic-clickable">
              <ion-col>
                <img src="assets/pix/content/Dachlattung.jpg" alt="Dachlattung">
              </ion-col>
              <ion-col>
                <span>Dachlattung</span>
              </ion-col>
              <input id="dachlattung" v-model="verbindungsart" @click="changePage()" type="radio" value="dachlattung">
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="hinweis">
              <b>Hinweis: </b>Die Auswahl der Verbindungsart für die Dachlattung bezieht sich auf die "Hinweise Holz
              und Holzwerkstoffe" des ZVDH Ausgabe 11.2017
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="btn-back" size="4">
            <ion-button @click="goBack()" mode="ios">Zurück</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import partialsSteps from "@/views/partials/steps";

export default defineComponent({
  name: 'Verbindungsart',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    partialsSteps
  },
  computed: {
    verbindungsart: {
      get() {
        return store.getters.getByProperty("verbindungsart")
      },
      set(value) {
        store.dispatch("resetStore").then(function() {
          store.commit("set", {property: 'verbindungsart', value: value});
        });
      }
    }
  },
  methods: {
    changePage: function () {
      const targets = {
        holzwerkstoffplatte: '/Holzwerkstoffplatte/Plattenart',
        dachlattung: '/Dachlattung/Windzone',
      }

      setTimeout(() => {
        this.$router.push(targets[store.getters.getByProperty("verbindungsart")]);
      }, 50);
    },

    goBack: function () {
      this.$router.back();
    }
  }
});
</script>
