<template>
  <ion-page>
    <ion-content class="zwischenseite">
      <ion-grid>
        <ion-row>
          <ion-col class="btn-back" mode="ios">
            <ion-button href="/home">Zurück</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>
              Für Ihre Auswahl und die Berechnung benötigen Sie den Kundensupport.<br>
              Bitte setzen Sie sich mit uns in Verbindung.
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4" class="helpPictureWrapper">
            <img src="assets/pix/content/help-button.jpg">
          </ion-col>
          <ion-col size="7" class="helpTextWrapper">
            <h3>Kundensupport</h3>
            <a href="mailto:anwendungstechnik@itw-befestigungssysteme.de">anwendungstechnik@itw-befestigungssysteme.de</a><br><br>
            <a href="tel:+49 511-4204 212">+49 511-4204 212</a>
          </ion-col>
        </ion-row>
        <ion-col>
          <div class="btn-primary btn-big" mode="ios">
            <ion-button class="button-round" expand="full" href="/home">
              Zur Startseite
            </ion-button>
          </div>
        </ion-col>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter} from "vue-router";
import {store} from "@/store/formtemplate";
import {productsstore} from "@/store/products";


export default defineComponent({
  name: 'Zwischenseite',
  setup() {
    return {
      router: useRouter()
    }
  },
  components: {
    IonContent,
    IonGrid,
    IonCol,
    IonPage
  },
  methods: {
    resetFormTemplate: function () {
        this.$router.push("/verbindungsart").then(function () {
          store.dispatch("resetStore");
        });
      },
  },
});

</script>
