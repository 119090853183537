<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1>Ihr Suchergebnis:</h1>
          </ion-col>
        </ion-row>
        <ion-row v-if="getProducts.showNagelInfo">
          <ion-col>
            <div class="btn-clickable alert">
              <vue-collapsible-panel-group>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <h1>Achtung!</h1>
                    Für diese Auswahl benötigen Sie zwei Nägel.
                  </template>
                  <template #content class="btn-clickable">
                    Es sind gemäß Ihren Angaben 2 Nägel je Anschlusspunkt zu setzen und folgende Randabstände
                      einzuhalten. Die Mindestlattenbreite beträgt 53 mm.<br><br>
                    <img src="assets/pix/content/Suchergebnis-Streifennaegel.jpg">
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </ion-col>
        </ion-row>
        <div class="productWrapper" v-if="getProducts.showProducts">
          <div v-for="(productItem, key) in getProducts.products" :key="productItem.id">
            <div class="productInner" v-if="productItem.length">
              <ion-row>
                <ion-col>
                  <label class="pic-text">
                    <ion-row>
                      <ion-col>
                        <img :src="getImageName(key)" :alt="key">
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        {{key}}
                      </ion-col>
                    </ion-row>
                  </label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ul class="ergebnisliste">
                    <li v-for="item in productItem" :key="item.Artikelnummer">
                      <ion-row>
                        <ion-col class="ergebnisArtikel">
                          <router-link :to="{ name: 'produkt', params: { id: item.Artikelnummer }}">
                            <h3>{{ item.Titel }}</h3>
                            <span>Passt zu Gerät: {{getNormen(item)}}</span>
                          </router-link>
                        </ion-col>
                      </ion-row>
                    </li>
                  </ul>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </div>

        <ion-row v-else>
          <ion-col><h3>Leider wurde kein Ergebnis gefunden</h3></ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="btn-back" size="4" mode="ios">
            <ion-button @click="goBack()">Zurück</ion-button>
          </ion-col>
          <ion-col class="btn-primary" size="7" mode="ios">
            <ion-button href="/home">Neue Suche starten</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import {defineComponent} from 'vue';
import {store} from "@/store/formtemplate";
import {productsstore} from "@/store/products";
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';

export default defineComponent({
  name: 'Ergebniss',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel
  },
  computed: {
    getProducts() {
      if (store.getters.getByProperty("verbindungsart") == 'holzwerkstoffplatte') {
        return productsstore.getters.getFilteredHolzhws;
      } else {
        return productsstore.getters.getFilteredDachlattung;
      }
    }
  },
  methods: {
    getImageName: function(typ) {
      const mapping = {
        "Streifennägel": "assets/pix/content/Suchergebnis-Streifennaegel.jpg",
        "Draht-Coilnägel": "assets/pix/content/Sucherergebnis-Coilnaegel-Draht.jpg",
        "Plast-Coilnägel": "assets/pix/content/Suchergebnis-Coilnaegel-Plast.jpg",
        "Klammern": "assets/pix/content/Suchergebnis-BS-Klammern.jpg"
      };

      return mapping[typ];
    },
    goBack: function () {
      this.$router.back();

      store.commit("set", {property: 'einbausituation', value: ""});
      store.commit("set", {property: 'sparrenabstand', value: ""});
    },
    getNormen: function (product) {
      const temp = [];
      const names = ["Product1", "Product2", "Product3"];

      names.forEach(function(name){
        if(product[name] != null)
          temp.push(product[name]);
      });

      return temp.length === 1 ? temp[0]: temp.join(", ");
    }
  }
});
</script>
